import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import Layout from '~components/layouts/Default';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="✅ Datenschutz - Deine Daten sind in guten Händen" desc="Die Sicherheit deiner Daten ist uns sehr wichtig, daher arbeiten wir Datenschutzkonform. In den Datenschutzbestimmungen erfährst du wie.">
      <PageHeader title="Datenschutz" breadcrumb={[{ title: 'Datenschutz', link: '/datenschutz/' }]} />
      <Container>
        <div className="prose prose-lg mx-auto mt-6">{parse(data.rechtliches.text)}</div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    rechtliches(title: { eq: "Datenschutz" }) {
      text
    }
  }
`;
